.container3 {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    margin-left: 250px; /* Consider adjusting this value or using media queries */
    box-sizing: border-box; /* Ensures padding and border are included in the width/height */
    width: calc(100% - 260px); /* Adjust the width to prevent overflow, accounting for margin */
}

@media (max-width: 768px) {
    .container3 {
        margin-left: 20px; /* Smaller margin for smaller screens */
        width: calc(100% - 40px); /* Adjust width accordingly */
    }
}


.main-top {
    background-color: #FAD034;
    color: #fff;
    padding: 10px;
    vertical-align: bottom;
    border-radius: 10px 10px 10px 10px;
}

.table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.table th,
.table td {
    padding: 10px;
    border: 1px solid #000000;
}

.table th {
    background-color: #f2f2f2;
}

.btn-edit,
.btn-delete,
.btn {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
}

.btn-edit {
    background-color: #1e90ff;
    margin-right: 5px;
}

.btn-delete {
    background-color: #ff6347;
    margin-right: 5px;
}

.btn {
    background-color: #4caf50;
}

.btn:hover {
    opacity: 0.8;
}

.search-bar {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 10px;
    background-color: #fff;
    width: 300px; /* Adjust the width as needed */
}

.search-prefix {
    padding: 5px;
    color: #777;
}

.search-input {
    flex-grow: 1;
    border: none;
    outline: none;
    font-size: 16px;
    padding: 5px;
}
