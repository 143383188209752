/* .container{
    background-color:#1e90ff;
    border-radius: 10px;
    height: 100px;
}

.nav-link{
    color: rgb(0, 0, 0);
    font-size: 20px;
    font-weight: bold;
}

.nav-link:hover{
    margin-left: 20px;
    margin-right: 20px;
    background-color: rgb(0, 0, 0);
    border-radius: 10px;
    color: #1e90ff

}
 */


 .sidebar {
    width: 250px;
    height: 100%;
    background-color: #215D0F;
    color: white;
    position: fixed;
    top: 0;
    left: 0;
    overflow-x: hidden;
    padding-top: 20px;
}

.sidebar-logo {
    display: block; /* Ensures the logo takes up the full width available */
    max-width: 100%; /* Makes sure the logo is not bigger than its container */
    height: auto; /* Maintains the aspect ratio of the image */
    margin: 0 auto 20px; /* Centers the logo and adds some space below it */
    padding: 10px; /* Optional: adds some padding around the logo */
}


.sidebar-nav {
    list-style: none;
    padding: 0;
    margin-top: 20px;
}

.sidebar-item {
    margin-bottom: 10px;
}

.sidebar-link {
    color: white;
    text-decoration: none;
    display: block;
    padding: 10px;
    transition: background-color 0.3s ease-in-out;
}

.sidebar-link:hover {
    background-color: #133104; /* Darker green background on hover */
    color: white; /* Explicitly setting the text color to white */
    cursor: pointer;
}


.icon {
    margin-right: 20px;
    margin-left: 20px;
}

/* navbar.css */
.dropdown-content {
    display: none;
    position: absolute;
    left: 50px;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 6px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

/* Style the links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 5px 6px;
    text-decoration: none;
    display: block;
    
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown content when hovering over the dropdown class */
.sidebar-item.dropdown:hover .dropdown-content {
    display: block;
}

/* Ensure the sidebar link doesn't move on hover */
.sidebar-item.dropdown:hover .sidebar-link {
    background: none;
    color: inherit;
}