/* AddWaste.css */
/* Custom form control styles to ensure consistency */
.form-control-custom {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
     margin-bottom: 10px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Specific to DatePicker to match other form elements */
.react-datepicker-wrapper,
.react-datepicker__input-container input {
    width: 100%; /* Match the width of other form controls */
}

/* Example to ensure label consistency */
.form-label-custom {
    /* Your custom label styles here to match other labels */
    color: #333; /* Example color */
    margin-bottom: 10px;
}
