

/* .home {
  background-image: url("../public/home1.jpg");
  background-size: cover;
  min-height: 100vh;
  align-items: center;
}

.home h1 {
  margin: 0;
  color: #F1EBE4;
  float: left;
  font-family: 'Handlee', cursive;
  padding: 90px 150px;
  font-size: 70px;
}

.home h3 {
  margin: 0;
  color: #c8c2bb;
  float: left;
  padding: 80px 90px;
  font-size: 40px;
  width: 65%;
}

.home button {
  position: relative;
  float: left;
  top: 45%;
  left: -50%;
  margin: 10px 20px;
  border-radius: 10px;
}

.logo {
  width: 250px;
  padding: 0.4em;
}

.new-form {
  padding: 100px;
}

.home-img {
  position: fixed;
  bottom: -10%;
  top: -10%;
  left: 78%;
  width: 22%;
}

.transparent-box {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 20px;
  align-items: center;
  height: 75%;
  width: 85%;
  border-radius: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  backdrop-filter: blur(15px);
}

.about {
  background-image: linear-gradient(gainsboro, white);
  background-size: cover;
  min-height: 100vh;
  align-items: center;
}

.index {
  background-image: linear-gradient(gainsboro, white);
  background-size: cover;
  min-height: 100vh;
}

Main {
  background-image: linear-gradient(gainsboro, white);
  background-size: cover;
  min-height: 100vh;
}

.about h2 {
  margin: 0;
}

.about-text {
  color: rgb(116, 115, 115);
  font-size: 1.3em;
  width: 600px;
  position: relative;
  padding: 10%;
}

.bg {
  width: 40em;
  height: 40em;
  border: none;
  border-radius: 50%;
  background-color: #b0bfaf;
  position: absolute;
  bottom: 25%;
  left: 54%;
}

.plant {
  position: absolute;
  bottom: 18%;
  left: 55%;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(56, 64, 60, 0.6);
  padding: 1em;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color:#F1EBE4;
}

.nav {
    display: flex;
    position: fixed;
    justify-content: space-around;
    background-color: rgb(56, 64, 60, 0.6);
    /* background-color: rgb(151, 151, 151, 0.5); */
    /* padding: 5px;
    width: 100%;
    margin: auto;
    font-weight: bold;
    z-index: 1;
    font-size: 20px;
} */

/* .nav-item {
  flex-basis: 200px;
}

.nav-item a {
  text-decoration: none;
  color: #F1EBE4;
  padding-inline: 20px;
}

.nav-item a:hover{
  text-decoration: none;
  color: rgb(77, 127, 96);
}

@media (max-width: 1280px) {
  .nav {
      justify-content: center;
      padding: 20px;
  }

  .nav-item {
      flex-basis: 100%;
      text-align: center;
      padding: 10px;
      font-size: 10px;
  }

  .category-dropdown {
    flex-basis: 100%;
    text-align: center;
    padding: 10px;
    font-size: 10px;
  }
}

.link-container {
  display: flex;
  flex-direction: row;
  position: relative;
  top: 1rem;
}

.social-media {
  padding: 0 10px;
}

.link {
  text-decoration: none;
  cursor: pointer;
  padding-inline: 30px;
  position: relative;
}

.link:active, :visited {
  color: #F1EBE4;
}

.link:hover {
  color:  rgb(77, 127, 96);
} */



/* .category-dropdown {
  display: none;
  position: absolute;
  z-index: 1;
  background-color: rgb(56, 64, 60);
  border-radius: 5px;
  padding: 1rem;
  font-size: 18px;
}

.nav-item:hover .category-dropdown {
  display: block;
}

.category-dropdown:hover {
  color: rgb(156, 199, 172);
}

.contact-us {
  background-image: url("../public/home.jpg");
  background-size: cover;
  min-height: 100vh;
  align-items: center;
  width: 100%;
}

.contact-us h1 {
  margin: 0;
  padding: 2%;
  color:rgb(67, 52, 52);
  position: relative;
  top: 5em;
  left: 10em;
}


button {
  border: none;
  background-color: #b0bfaf;
  color: white;
  font-size: 1.15rem;
  font-weight: 500;
  padding: 0.8rem 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  cursor: pointer;
  transform: translate(1) translate(0, 0);
  transition: transform 225ms, box-shadow 225ms;
}

button:hover {
  transform: scale(1.05) translate(0, -0.15rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.35);
}

button:active {
  transform: scale(1) translate(0, 0.15rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
} */
*, *:before, *:after {
  box-sizing: border-box;
}

.loading-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading {
  font-size: 5rem;
  font-weight: 200;
}
.return-link {
  font-size: 2rem;
  font-weight: 300;
  display: block;
  text-decoration: none;
  margin-bottom: 2rem;
  color: #000 !important;
}