.no-list {

    list-style-type: none;
    padding: 0;
    margin: 0;

}
.no-list li {

    padding: 0;
    margin: 0;

}
.no-list li a {

    padding: 0;
    margin: 0;
    display: block;

}
body {

    font-family: 'Lato', sans-serif;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    font-weight: 300;

}
*:before, *:after {

    font-family: FontAwesome;

}
aside.sidebar {

    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    background: #222;
    width: 50px;
    overflow: hidden;
    transition: all 0.5s;

}
aside.sidebar:hover {

    width: 200px;

}
aside.sidebar:hover ul.sb_top_lv li span {

    left: 50px;
    transition-delay: 0.318s;
    opacity: 1;

}

aside.sidebar ul.sb_top_lv li {

    float: left;
    width: 100%;
    color: white;
    position: relative;
    padding: 10px 15px;
    text-align: left;
    transition: all 0.5s;

}
aside.sidebar ul.sb_top_lv li:hover {
    cursor: pointer;
    background: #111;
}
aside.sidebar ul.sb_top_lv li.opened ul.sb_dropdown {
    height: 500px;
    opacity: 1;
    left: 0;
}
aside.sidebar ul.sb_top_lv li span {
    transition: all 0.2s;
    position: absolute;
    left: 60px;
    font-size: 12px;
    top: 12px;
    opacity: 0;
}
aside.sidebar ul.sb_top_lv li i {
    font-size: 18px;
}
aside.sidebar ul.sb_dropdown {
    transition: all 0.5s;
    position: relative;
    opacity: 0;
    top: 0;
    height: 0px;
    overflow: hidden;
    width: 200px;
    left: 25px;
}
aside.sidebar ul.sb_dropdown p {
    text-align: center;
}
aside.sidebar ul.sb_dropdown li a {
    color: white;
    font-size: 12px;
}
aside.sidebar ul.sb_dropdown li a:before {
    content: "\f10c";
    padding-right: 5px;
}

.table-container {
    max-width: 100%;
    overflow: auto; /* Add horizontal scroll on smaller screens */
}

table {
    width: 100%;
    max-width: 100%; /* Prevent it from exceeding the parent's width */
    border-collapse: collapse;
    box-sizing: border-box; /* Include padding and border in the width */
}

th, td {
    padding: 8px;
    border: 1px solid #ccc;
    text-align: left;
}



/* Styles.css - Adding responsive styles for tablet screens */

@media screen and (max-width: 1024px) {
    .container3, .main-top, .main {
      padding: 10px; /* Adjust padding for smaller screens */
      width: auto; /* Ensure full width usage */
    }
  
    .table {
      font-size: 14px; /* Adjust font size for table */
    }
  
    input[type="text"] {
      width: 90%; /* Increase width for search inputs */
    }
  
    /* Add additional styles as needed for other elements */
  }
  

  .container3 {
    width: calc(100vw - 40px); /* Assuming 20px padding on each side */
    padding: 20px;
    margin: auto; /* Center the container */
    box-sizing: border-box; /* Include padding in the width calculation */
}

.main-title {
    text-align: center;
    margin-bottom: 20px;
}

.main-content {
    max-width: calc(100vw - 40px); /* Adjust according to padding in .container3 */
    margin: auto; /* Center the content */
    width: 100%; /* Ensure it occupies the available space */
    box-sizing: border-box; /* Include padding in the width calculation */
}


.button-group {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.add-waste-btn {
    margin-top: 20px;
    text-transform: none;
}

.date-range-picker {
    margin-left: 10px;
    border: 1px solid black;
    border-radius: 5px;
    margin-top: 20px;
}

.waste-table, .waste-map {
    max-width: 90vw;
    width: 100%;
    margin: auto;
}