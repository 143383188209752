#body-bg {
    background-color: #525753;
    flex-direction: column;
    
}

/* Centering the loader */
/* Centering the loader within a specific area */
.page-container {
    position: relative;
    min-height: 100vh; /* Ensures the container takes at least the full viewport height */
}

.center-loader {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999; /* Adjust the z-index value as needed to ensure it appears on top */
    /* Additional styling for the loader as needed */
}

  

#form-bg {
    background-color: #090a09;
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,.75);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,.75);
    box-shadow: 0 2px 5px #b6aeaebf;
    
}

#logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #logo {
    width: 80px;
    height: 80px;
  }

#form-bg > h2 {
    color: white;
    margin-bottom: 2rem;
    margin-top: 2rem;
    text-align: center;
}

.dont {
    color: #7f7f81;
    margin-top: 5px;
}

#form-bg > input {
    font-size: small;
}

#signin {
    color: #7f7f81;
    font-size: 13px;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
}

#or {
    color: #7f7f81;
    font-size: 13px;
    justify-content: center;
    text-align: center;
    margin-top: 2px;
    margin-bottom: -2px;
}

#d-login, #d-pw {
    margin: 2px;
}

label {
    color: #fefeff;
}

#login-input, #pw-input {
    background-color: #1e1d22;
    color: white;
    font-size: 10px;
    border-radius: 10px;
    border-color: #333237;
    margin-top: 8px;
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-clip: padding-box;
    border: var(--bs-border-width) solid #333237;
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

#login-input:focus, #pw-input:focus{
    border-color: #7751dc;
    outline: none;
    background-color: #2c2a38;
}

#login-input::placeholder, #pw-input::placeholder {
    font-size: 13px;
}

#login-input:hover, #pw-input:hover {
    border-color: #7751dc;
    background-color: #2c2a38;
}

.action-container {
    text-align: center;
}

.btn-login {
    background-color: #7751dc;
    color: #fffefd;
    
    font-size: 12px;
    
    width: 300px;
    height: 40px;
    margin-top: 20px;
    
    border: none;
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,.75);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,.75);
    box-shadow: 0 2px 2px #000000bf;

    transform-origin: center;
}

.btn-login:hover {
    cursor: pointer;
    background-color: #5D3EB2;
    transform: scale(1.05)
}

.btn-login:active {
    background-color: #403366;
    transform: scale(0.95)
}

.btn-create {
    background-color: #ffffff;
    color: #fffefd;
    
    font-size: 16px;
    
    width: 360px;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 20px;
    
    border: 2px solid #333237;
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,.75);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,.75);
    box-shadow: 0 2px 2px #000000bf;

    transform-origin: center;
}

.btn-create:hover {
    cursor: pointer;
    background-color: #5D3EB2;
    transform: scale(1.05)
}

.btn-create:active {
    background-color: #403366;
    transform: scale(0.95)
}

.auth-message {
    color: white;
}

.link-container {
    text-align: center;
    background-color: #1e1d22;
    
    width: 400px;
    margin: 5px;
    
    border-radius: 10px;
    -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,.75);
    -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,.75);
    box-shadow: 0 2px 5px #000000bf;
}
/* Home.css - Adding responsive styles for tablet screens */

@media screen and (max-width: 1024px) {
    .container3, .main-top, .main {
      padding: 10px; /* Adjust padding for smaller screens */
      width: auto; /* Ensure full width usage */
    }
  
    .table {
      font-size: 14px; /* Adjust font size for table */
    }
  
    input[type="text"] {
      width: 90%; /* Increase width for search inputs */
    }
  
    /* Adjust styles for buttons, headings, and other elements as needed */
  }
  